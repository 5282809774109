import { gql } from '@/shared/utils';

export default gql`
  query GetAllCourses($organisationCode: String!) {
    courses(organisationCode: $organisationCode) {
      _id
      category {
        name
        backgroundImage
        themeColorHex
        eduframeCategoryId
      }
      slug
      duration
      title
      shortDescription
      nextAvailableDate
    }
  }
`;
