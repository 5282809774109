import { useContext } from '@nuxtjs/composition-api';
import { AnalyticsEventInformation } from './analyticsEvents.types';

export const useCheckAnalyticsEvent = () => {
  const ctx = useContext();

  const checkAnalyticsEvent = async (
    analyticsEventInfo: AnalyticsEventInformation
  ) => {
    try {
      const response = await ctx.$axios.post<boolean>(
        '/api/v1/ecommerce/check-analytics-event',
        analyticsEventInfo
      );

      return response.data;
    } catch {
      return false;
    }
  };

  return {
    checkAnalyticsEvent,
  };
};
