import { ref, useContext } from '@nuxtjs/composition-api';
import { GigyaMarketingSignupPayload } from './useGigya.types';
import { API } from '@/config/api.config';

export const useGigyaMarketingSignup = () => {
  const { $axios, $sentry } = useContext();
  const isSubmitPending = ref<boolean>(false);

  const sendGigyaMarketingPreferences = async (
    signupPayload: GigyaMarketingSignupPayload
  ) => {
    isSubmitPending.value = true;

    try {
      await $axios.post(API.marketing.marketingSignup, signupPayload);
    } catch (error) {
      $sentry.captureException(error);
      throw error;
    } finally {
      isSubmitPending.value = false;
    }
  };

  return { sendGigyaMarketingPreferences, isSubmitPending };
};
