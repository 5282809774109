import { EcommerceDataLayerEventData } from '~/shared/types/window/window.types';

export enum AnalyticsEventType {
  ADD_PAYMENT_INFO = 'add_payment_info',
  ADD_BILLING_INFO = 'add_billing_info',
  PURCHASE = 'purchase',
  REQUEST_QUOTE = 'request_quote',
  MARKETING_ALERTS_SUBMIT = 'marketing_alerts_submit',
  B2B_ENQUIRY_SUBMIT = 'b2b_enquiry_submit',
  SELECT_FAQ_TAB = 'select_faq_tab',
  VIEW_FAQ_QUESTION = 'view_faq_question',
}

export enum DataLayerEventPaymentMethod {
  Card = 'Card',
  PayPal = 'PayPal',
  Quote = 'Quote',
}

export type EcommerceEventData =
  | EcommercePurchaseEventData
  | EcommerceQuoteRequestEventData
  | EcommercePaymentEventData
  | EcommerceBillingEventData
  | MarketingAlertsEventData
  | B2bEnquirySubmitEventData
  | SelectFaqTabEventData
  | ViewFaqQuestionEventData;

export type EcommercePurchaseEventData = {
  event: AnalyticsEventType.PURCHASE;
  ecommerce: EcommerceDataLayerEventData & { transaction_id: string };
};

export type EcommerceQuoteRequestEventData = {
  event: AnalyticsEventType.REQUEST_QUOTE;
  ecommerce: EcommerceDataLayerEventData & { transaction_id: string };
};

export type EcommercePaymentEventData = {
  event: AnalyticsEventType.ADD_PAYMENT_INFO;
  ecommerce: EcommerceDataLayerEventData & {
    payment_method: DataLayerEventPaymentMethod;
  };
};

export type EcommerceBillingEventData = {
  event: AnalyticsEventType.ADD_BILLING_INFO;
  ecommerce: EcommerceDataLayerEventData;
};

export type MarketingAlertsEventData = {
  event: AnalyticsEventType.MARKETING_ALERTS_SUBMIT;
  course_interest: string;
};

export type B2bEnquirySubmitEventData = {
  event: AnalyticsEventType.B2B_ENQUIRY_SUBMIT;
  course_interest: string;
};

export type AnalyticsEventInformation = {
  orderId: string;
  eventType: AnalyticsEventType;
};

export type SelectFaqTabEventData = {
  event: AnalyticsEventType.SELECT_FAQ_TAB;
  tab_name: string;
};

export type ViewFaqQuestionEventData = {
  event: AnalyticsEventType.VIEW_FAQ_QUESTION;
  faq_question: string;
  faq_section: string;
};
