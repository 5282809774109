
import { getCurrentInstance } from 'vue';
import { defineComponent, reactive, ref } from '@nuxtjs/composition-api';
import { MarketingSignUpState } from './marketingSignUpForm.types';
import SectionContentContainer from '@/components/section-content-container/SectionContentContainer.vue';
import BaseButton from '@/components/buttons/base-button/BaseButton.vue';
import FormInputField from '@/components/form/FormInputField.vue';
import FormSelectField from '@/components/form/FormSelectField.vue';
import FormRequiredFieldInfo from '@/components/form/FormRequiredFieldInfo.vue';
import FormFieldGroup from '@/components/form/FormFieldGroup.vue';
import { Course } from '@/shared/types';
import { focusOnFirstElement } from '@/shared/utils';
import FormWrapper from '@/components/form/FormWrapper.vue';
import TechnicalSupportMessage from '@/components/messages/TechnicalSupportMessage.vue';
import InterestRegisterSuccessMessage from '@/components/messages/InterestRegisterSuccessMessage.vue';
import AlertMessageCard from '@/components/cards/alert-message-card/AlertMessageCard.vue';
import {
  useUTMPipeStringFormat,
  useUTMValues,
} from '~/composables/utm/useUTMValues';
import { useCaptchaV3 } from '~/composables/captchaV3/useCaptchaV3';
import { CaptchaV3Actions } from '~/composables/captchaV3/useCatptchaV3.types';
import PrivacyInfoCard from '@/components/cards/privacy-info-card/PrivacyInfoCard.vue';
import useGetAllCourses from '~/composables/course/useGetAllCourses';
import { useGigyaMarketingSignup } from '~/composables/gigya/useGigyaMarketingSignup';
import Loader from '~/components/loader/Loader.vue';
import Error from '~/layouts/error.vue';
import { useGetBillingCountries } from '~/composables/booking/useBillingInfo';
import FormCountryField from '~/components/form/FormCountryField.vue';
import { usePushDataLayerEvent } from '~/composables/analyticsEvents/usePushDataLayerEvent';
import { AnalyticsEventType } from '~/composables/analyticsEvents/analyticsEvents.types';

export default defineComponent({
  components: {
    BaseButton,
    SectionContentContainer,
    FormFieldGroup,
    FormInputField,
    FormRequiredFieldInfo,
    FormWrapper,
    InterestRegisterSuccessMessage,
    TechnicalSupportMessage,
    AlertMessageCard,
    PrivacyInfoCard,
    FormSelectField,
    Loader,
    Error,
    FormCountryField,
  },
  props: {
    organisationCode: {
      type: String,
      default: '',
    },
    showCourseOfInterest: {
      type: Boolean,
      default: true,
    },
    courseName: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const instance = getCurrentInstance();
    const { pushDataLayerEvent } = usePushDataLayerEvent();
    const { billingCountries } = useGetBillingCountries();
    const countryPrefill = ref<{ code: string } | undefined>({ code: '' });
    const { $sentry } = instance!.proxy;

    const { allCourses, isFetchingAllCourses, allCoursesFetchingErrorOccured } =
      useGetAllCourses();

    const utmValues = useUTMValues();
    const utmString = useUTMPipeStringFormat(utmValues);
    const { isSubmitPending, sendGigyaMarketingPreferences } =
      useGigyaMarketingSignup();
    const state = reactive<MarketingSignUpState>({
      form: {
        firstName: '',
        lastName: '',
        email: '',
        coursesOfInterest: [],
        country: '',
      },
      isSubmitPending: false,
      isSubmitError: false,
      isSubmitSuccess: false,
    });

    const handleCountryChange = (country: { label: string; code: string }) => {
      state.form.country = country.code;
    };

    const { getTokenCaptchaV3 } = useCaptchaV3();

    const onCourseSelected = (selectedCourses: Partial<Course>[]) => {
      state.form = {
        ...state.form,
        coursesOfInterest: selectedCourses,
      };
    };

    const resetFormHandler = () => {
      state.form = {
        firstName: '',
        lastName: '',
        email: '',
        coursesOfInterest: [],
        country: '',
      };
      countryPrefill.value = undefined;
    };

    const submitFormHandler = async () => {
      try {
        const token = await getTokenCaptchaV3(
          CaptchaV3Actions.MARKETING_SIGN_UP
        );
        await onMarketingFormSubmission(token);
      } catch (error) {
        $sentry.captureException(error);
        state.isSubmitError = true;
      }
    };

    const onMarketingFormSubmission = async (
      captchaToken: string
    ): Promise<void> => {
      state.isSubmitPending = true;
      state.isSubmitError = false;
      state.isSubmitSuccess = false;

      const singleCourseValue = props.courseName;

      const multipleCourses = state.form.coursesOfInterest.map(
        (course) => course.title!
      );

      try {
        await sendGigyaMarketingPreferences({
          token: captchaToken,
          leadSource: utmString ?? '',
          profile: {
            firstName: state.form.firstName,
            lastName: state.form.lastName,
            email: state.form.email.toLowerCase(),
            country: state.form.country,
          },
          courseTitle: singleCourseValue
            ? [singleCourseValue]
            : multipleCourses,
          skipSendingBrochure: true,
          organisationCode: props.organisationCode,
          acceptedMarketingPreferences: true,
        });

        state.isSubmitSuccess = true;

        pushDataLayerEvent({
          event: AnalyticsEventType.MARKETING_ALERTS_SUBMIT,
          course_interest: props.courseName
            ? props.courseName
            : state.form.coursesOfInterest
                .map((course) => course.title)
                .join(',') || 'not selected',
        });

        resetFormHandler();
        setTimeout(() => {
          state.isSubmitSuccess = false;
          state.isSubmitPending = false;
        }, 3000);
      } catch (_) {
        state.isSubmitError = true;
        state.isSubmitPending = false;
      }
    };

    return {
      billingCountries,
      state,
      onMarketingFormSubmission,
      submitFormHandler,
      focusOnFirstElement,
      onCourseSelected,
      isSubmitPending,
      allCourses,
      isFetchingAllCourses,
      allCoursesFetchingErrorOccured,
      countryPrefill,
      handleCountryChange,
    };
  },
});
