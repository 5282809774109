import dayjs from 'dayjs';
import { useContext } from '@nuxtjs/composition-api';
import { UtmTags } from './utm.types';
import { removeEmptyFields } from '@/shared/utils';
import {
  UTM_VALUES_LOCAL_STORAGE_KEY,
  UTM_VALUES_LAST_UPDATE_LOCAL_STORAGE_KEY,
} from '~/shared/constants';

export const useUTMValues = (): UtmTags | undefined => {
  if (typeof window === 'undefined') return;
  const utmValues = localStorage.getItem(UTM_VALUES_LOCAL_STORAGE_KEY);
  const utmTimeout = localStorage.getItem(
    UTM_VALUES_LAST_UPDATE_LOCAL_STORAGE_KEY
  );
  const ctx = useContext();
  if (!utmValues || !utmTimeout) return;
  try {
    const maxAllowedUtmTtl =
      Number(ctx.$config.MAX_ALLOWED_UTM_TTL_MINUTES) || 1440;
    if (dayjs().isAfter(dayjs(utmTimeout).add(maxAllowedUtmTtl, 'minutes')))
      return;

    return {
      ...removeEmptyFields(JSON.parse(utmValues)),
    };
  } catch {}
};

export const useUTMPipeStringFormat = (
  utmValues?: UtmTags
): string | undefined => {
  if (!utmValues) {
    return;
  }

  const utmSource = utmValues.utm_source || '';
  const utmMedium = utmValues.utm_medium || '';
  const utmCampaign = utmValues.utm_campaign || '';
  const utmPipeString = `${utmSource}|${utmMedium}|${utmCampaign}`
    .substring(0, 255)
    .replace(/^\|/, '')
    .replace(/\|$/, '');

  return utmPipeString;
};
