
import { getCurrentInstance } from 'vue';
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { FeatureFlagName } from '@/shared/types/feature-flags/feature-flags';
import { ZENDESK_HELP_CENTRE } from '@/shared/constants';

export default defineComponent({
  setup: () => {
    const instance = getCurrentInstance();
    const { $config, $isFeatureEnabled } = instance!.proxy;
    const siteUrl = $config?.SITE_URL;
    const isZendeskEnabled = $isFeatureEnabled(
      FeatureFlagName.ZENDESK_REDIRECT
    );
    const redirectUrl = computed(() => {
      return isZendeskEnabled ? ZENDESK_HELP_CENTRE : `${siteUrl}/contact-us`;
    });

    return {
      redirectUrl,
    };
  },
});
