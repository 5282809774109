import { useContext } from '@nuxtjs/composition-api';
import {
  EcommerceEventData,
  AnalyticsEventType,
} from './analyticsEvents.types';
import { useCheckAnalyticsEvent } from './useCheckAnalyticsEvent';

export const usePushDataLayerEvent = () => {
  const { checkAnalyticsEvent } = useCheckAnalyticsEvent();
  const { $sentry } = useContext();

  const pushDataLayerEvent = async (eventData: EcommerceEventData) => {
    if (!window.dataLayer) {
      const error = new Error('ERROR_GTM_DATA_LAYER_NOT_EXISTS');
      $sentry.captureException(error);
      return;
    }

    if (
      eventData.event === AnalyticsEventType.PURCHASE ||
      eventData.event === AnalyticsEventType.REQUEST_QUOTE
    ) {
      const isEventAlreadyRecorded = await checkAnalyticsEvent({
        eventType: eventData.event,
        orderId: eventData.ecommerce.transaction_id,
      });

      if (isEventAlreadyRecorded) return;
    }

    // Clearing the ecommerce object
    // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#clear-ecommerce
    window.dataLayer.push({ ecommerce: null });

    window.dataLayer.push(eventData);
  };

  return { pushDataLayerEvent };
};
